import { useConfig } from "../Config"
import { useStoreContext } from "../i18n/StoreHooks"

interface LocaleAwareForm {
  en: string
  is: string
}
export interface KlaviyoConfiguration {
  newsletterSignupForm: LocaleAwareForm
  privateApiKey: string
}
export const useKlaviyo = (): string | undefined => {
  const config = useConfig()

  return useStoreContext().isShoppingInIceland()
    ? config.klaviyo.newsletterSignupForm.is
    : config.klaviyo.newsletterSignupForm.en
}
