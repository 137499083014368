//jsxhook
import { LocalizedString } from "@commercetools/platform-sdk"
import React, { ReactNode } from "react"

const RelLink: React.FC<{
  hrefLang: string
  store?: string
  slug?: string
  domain?: string
  rel?: "canonical" | "alternate"
}> = ({
  hrefLang,
  store,
  slug,
  domain = new URL("https://www.66north.com"),
  rel = "alternate"
}) => {
  if (!slug) return null
  const path = [store, slug].join("/").replace(/^\/+|\/+$/g, "")
  return (
    <link
      rel={rel}
      hrefLang={hrefLang}
      href={`${domain}${path}`}
      key={hrefLang}
    />
  )
}

export const AlternateAndCanonicalUrlRelLinks: React.FC<{
  slug?: LocalizedString
}> = ({ slug = {} }): ReactNode => {
  const { en: english, is: icelandic } = slug
  return (
    <React.Fragment>
      <RelLink hrefLang={"en"} slug={english} />
      <RelLink hrefLang={"en-gb"} store={"uk"} slug={english} />
      <RelLink hrefLang={"en-us"} store={"us"} slug={english} />
      <RelLink hrefLang={"en-dk"} store={"dk"} slug={english} />
      <RelLink hrefLang={"en-es"} store={"eu"} slug={english} />
      <RelLink hrefLang={"en-fr"} store={"eu"} slug={english} />
      <RelLink hrefLang={"en-de"} store={"eu"} slug={english} />
      <RelLink hrefLang={"is"} store={"is"} slug={icelandic} />
    </React.Fragment>
  )
}
