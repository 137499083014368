import {
  HeadingRegularVariants,
  rawColorValues
} from "@sixty-six-north/ui-system"
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint
} from "@vis.gl/react-google-maps"
import React from "react"
import { Flex, Box, FlexProps } from "theme-ui"
import LatLngLiteral = google.maps.LatLngLiteral

export const MapMarker: React.FC<
  FlexProps &
    LatLngLiteral & {
      label?: string
    }
> = ({ label, children, lat, lng, ...props }) => {
  const location = { lat: lat || 0, lng: lng || 0 }
  const white = rawColorValues["white"][0]
  return (
    <AdvancedMarker
      key={"label"}
      position={location}
      anchorPoint={AdvancedMarkerAnchorPoint.LEFT_CENTER}
      title={label}
    >
      <Flex sx={{ height: 24, alignItems: "center" }}>
        <Box
          as="div"
          sx={{
            borderRadius: "1.5rem",
            width: 24,
            height: 24,
            borderColor: white,
            bg: "transparent",
            borderWidth: 4,
            borderStyle: "solid"
          }}
        ></Box>
        <Box
          role="text"
          sx={{
            flex: 1,
            ml: "0.5rem",
            color: white,
            variant: `text.${HeadingRegularVariants.sixth}`,
            lineHeight: "1rem",
            textShadow: "large"
          }}
        >
          {label}
        </Box>
      </Flex>
    </AdvancedMarker>
  )
}
