//jsxhook

import { rawColorValues, xmlToJson } from "@sixty-six-north/ui-system"
import fetch from "isomorphic-unfetch"
import React, { useEffect, useState } from "react"
import { Box } from "theme-ui"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { MapMarker, Polyline, GoogleMap } from "../../components/Maps"
import LatLngLiteral = google.maps.LatLngLiteral

export const GoogleMapSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)

  const gpxFileExists = adapter.gpxFile("").length > 0

  const [path, setPath] = useState<google.maps.LatLngLiteral[]>([])
  // only load this in once
  useEffect(() => {
    if (gpxFileExists) {
      fetch(adapter.gpxFile(""))
        .then(response => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(gpxData => {
          const json = xmlToJson(gpxData)
          return json.gpx.trk.trkseg.trkpt.map(point => {
            return {
              lat: Number(point["@attributes"].lat),
              lng: Number(point["@attributes"].lon)
            } as LatLngLiteral
          }) as LatLngLiteral[]
        })
        .then(response => {
          setPath(response)
        })
    }
  }, [])

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          pb: "56%",
          height: 0
        }}
      >
        <GoogleMap bounds={path}>
          {data.items &&
            data.items.map((marker, idx) => {
              const itemAdaptor = new PrismicItemAdapter(marker)

              const { latitude, longitude } = marker.coordinates || {}
              return (
                latitude &&
                longitude && (
                  <MapMarker
                    key={`gmap-marker-${idx})`}
                    label={itemAdaptor.label("")}
                    lat={latitude}
                    lng={longitude}
                  ></MapMarker>
                )
              )
            })}
          {path && path.length > 0 && (
            <Polyline
              strokeWeight={2}
              strokeColor={rawColorValues["white"][0]}
              strokeOpacity={0.7}
              geodesic={true}
              path={path}
            />
          )}
        </GoogleMap>
      </Box>
    </PrismicElementWrapper>
  )
}
