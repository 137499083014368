import { Deck, StandardXAxisPadding, H6 } from "@sixty-six-north/ui-system"
import { countryToPath } from "i18n/Country"
import { DEFAULT_REGION } from "i18n/Region"
import { PreviewData } from "next"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "theme-ui"
import { webNextPrismicApiUrl } from "../../Config"
import { useStoreContext } from "../../i18n/StoreHooks"
import { getDefaultLocaleForCountry } from "../../prismic/PrismicLocale"
import {
  PrismicDocument,
  PrismicSlice,
  PrismicSliceType,
  SimplePrismicDocument
} from "../../prismic/PrismicModels"
import { CardSlice } from "../../prismic/slices"

export interface ProductArticlesProps {
  tags?: string[]
  productId: string
  previewData?: PreviewData
  productPrismicData: SimplePrismicDocument | null
}

const MAX_ARTICLES = 3

const fetchProductArticles = async (
  documentIds: string[],
  tags: string[],
  locale: string,
  ref?: string
) =>
  await fetch(webNextPrismicApiUrl("fetchProductArticles"), {
    method: "POST",
    body: JSON.stringify({
      ids: documentIds,
      tags,
      locale,
      ref,
      limit: MAX_ARTICLES
    }),
    headers: {
      "Content-Type": "application/json"
    }
  })

const formatArticlesToCardSlice = ({ articles, t, country }): PrismicSlice => ({
  id: "articles-slice",
  slice_type: PrismicSliceType.card,
  primary: {
    aside: false,
    card_ratio: "4:3",
    color_mode: "light",
    color_text_override: "",
    color_background_override: "",
    columns: articles.length,
    display_as_gallery: false,
    header_distance: "with-navigation",
    layout: "equal",
    mobile_visual_rule: false,
    padding_bottom: "0",
    padding_top: "0",
    space_between: "none",
    width: "standard"
  },
  items: articles.map(article => ({
    card_text: article.data?.meta_description,
    card_title: article.data?.meta_title,
    image: article.data?.opengraph_image,
    link_1: {
      url: `/${country}${article.data?.path}`
    },
    link_1_label: [
      {
        text: t("checkout:learnMore")
      }
    ]
  }))
})

export const ProductArticles: React.FC<ProductArticlesProps> = ({
  productId,
  previewData,
  productPrismicData,
  tags = []
}) => {
  const { t } = useTranslation()
  const store = useStoreContext()
  const ref = (previewData as Record<string, string>)?.ref
  const productArticles = productPrismicData?.data?.product_articles || []
  const prismicLocale = getDefaultLocaleForCountry(store.country)
  const pathCountry = countryToPath(store.country || DEFAULT_REGION.country)
  const [matchedArticles, setMatchedArticles] = useState<PrismicDocument[]>([])

  useEffect(() => {
    async function fetchArticlesData(articleIds) {
      try {
        const response = await fetchProductArticles(
          articleIds,
          tags,
          prismicLocale,
          ref
        )
        const data = (await response.json()) || []
        setMatchedArticles(data)
      } catch (e) {}
    }

    let topArticlesIds: string[] = []

    // try to get product specific articles first
    if (productArticles.length > 0) {
      topArticlesIds =
        productArticles.map(a => a.product_article.id).slice(0, MAX_ARTICLES) ||
        []
    }

    fetchArticlesData(topArticlesIds)
  }, [productId])

  if (matchedArticles.length === 0) return null

  const formattedArticles = formatArticlesToCardSlice({
    articles: matchedArticles,
    country: pathCountry,
    t
  })

  return (
    <Box
      sx={{
        mt: [24, null, null, 0],
        mb: [24, null, null, 56],
        "& div": {
          pt: 0
        }
      }}
    >
      <Box
        sx={{
          mb: [16, 16, 16, 32],
          px: StandardXAxisPadding
        }}
      >
        <H6 as="h3">{t("product:nordurJournal")}</H6>
        <Deck
          as="h4"
          sx={{ display: "block", color: "grey.1", maxWidth: "32ch" }}
        >
          {t("product:storiesOfAdventure")}
        </Deck>
      </Box>
      <CardSlice data={formattedArticles} />
    </Box>
  )
}
