import { APIProvider } from "@vis.gl/react-google-maps"
import React, { ReactNode } from "react"
import { useConfig } from "../../Config"
import { usePreferredLanguage, useStoreContext } from "../../i18n/StoreHooks"
import logger from "../../utils/logger"

const devMapId = "c019f411ae84ea89"
const prodMapId = "331624da194a4345"

function determineMapId(apiKey: string): string {
  if (apiKey.endsWith("ZU8q_WDhqc")) {
    return devMapId
  }
  if (!apiKey.endsWith("_JRGU2ia2bzpWU")) {
    logger.warn("Using production mapId with unverified production API key")
  }
  return prodMapId
}

const MapIdContext = React.createContext(prodMapId)

export const useMapId = () => React.useContext(MapIdContext)

export const GoogleMapsProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const config = useConfig()
  const language = usePreferredLanguage()
  const { country } = useStoreContext()

  return (
    <APIProvider
      apiKey={config.google.mapKey}
      region={country}
      language={language}
    >
      <MapIdContext.Provider value={determineMapId(config.google.mapKey)}>
        {children}
      </MapIdContext.Provider>
    </APIProvider>
  )
}
