"use client"

import { Map, useMapsLibrary } from "@vis.gl/react-google-maps"
import React, { useEffect, useState } from "react"
import { Box } from "theme-ui"
import { useMapId } from "./GoogleMapsProvider"
import LatLngBoundsLiteral = google.maps.LatLngBoundsLiteral
import LatLngLiteral = google.maps.LatLngLiteral

interface MapProps {
  zoom?: number
  center?: LatLngLiteral
  children?: React.ReactNode
  bounds?: LatLngLiteral[]
}

interface MapConfig {
  defaultCenter?: LatLngLiteral
  defaultZoom?: number
  defaultBounds?: LatLngBoundsLiteral
}

// Known usages:
// - https://www.66north.com/uk/stores/regent-street
// - https://www.66north.com/nordur/winter-running-guide/maps
export const GoogleMap: React.FC<MapProps> = ({
  bounds,
  center = {
    lat: 66.131175,
    lng: -23.530843
  },
  zoom = 6,
  children
}) => {
  const coreLib = useMapsLibrary("core")
  const googleMapId = useMapId()

  const [mapDefaults, setMapDefaults] = useState<MapConfig | undefined>(
    undefined
  )

  useEffect(() => {
    if (!coreLib) return
    if (!bounds) {
      setMapDefaults({
        defaultZoom: zoom,
        defaultCenter: center
      })
    } else {
      const mapBounds = new coreLib.LatLngBounds()
      bounds?.forEach((location: LatLngLiteral) => {
        mapBounds.extend(location)
      })
      setMapDefaults({ defaultBounds: mapBounds.toJSON() })
    }
  }, [coreLib, bounds])

  return (
    mapDefaults && (
      <Box
        as="section"
        sx={{ position: "absolute", height: "100%", width: "100%" }}
      >
        <Map
          mapId={googleMapId}
          {...mapDefaults}
          streetViewControl={false}
          mapTypeControl={false}
        >
          {children}
        </Map>
      </Box>
    )
  )
}
