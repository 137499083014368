//jsxhook
import { FontWeight, Label2, Label3, Tooltip } from "@sixty-six-north/ui-system"
import { PreviewData } from "next"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Flex, Grid } from "theme-ui"
import { tooltipDataUrl } from "../../Config"
import { countryToPath } from "../../i18n/Country"
import { DEFAULT_REGION } from "../../i18n/Region"
import { useStoreContext } from "../../i18n/StoreHooks"
import { getDefaultLocaleForCountry } from "../../prismic/PrismicLocale"
import { Tooltip as TooltipData } from "../../prismic/PrismicModels"
import logger from "../../utils/logger"
import { KeyAndValue } from "../VariantProxy"

export interface TechnicalSpec {
  name: string
  values: KeyAndValue[]
}

const fetchTooltipsForLocale = async (locale: string, ref?: string) => {
  return await fetch(tooltipDataUrl(locale))
}

export const TechnicalSpecifications: React.FC<{
  values: TechnicalSpec[]
  previewData?: PreviewData
  warrantyAndRepair?: React.ReactNode
}> = ({ values, previewData, warrantyAndRepair }) => {
  const store = useStoreContext()
  const prismicLocale = getDefaultLocaleForCountry(store.country)
  const [technicalSpecTooltipDocuments, setTechnicalSpecTooltipDocuments] =
    useState<TooltipData[]>([])

  const { t } = useTranslation("product")

  useEffect(() => {
    async function fetchTooltipDocuments() {
      try {
        const ref: string = (previewData as Record<string, string>).ref
        const response = await fetchTooltipsForLocale(prismicLocale, ref)
        const tooltips = (await response.json()) || []
        setTechnicalSpecTooltipDocuments(tooltips)
      } catch (e) {
        logger.warn("error", e)
      }
    }

    fetchTooltipDocuments()
  }, [])

  function toTooltipOrLabel(index, technicalSpecKeyword, idx) {
    const technicalTooltip = technicalSpecTooltipDocuments?.find(
      toolTip => toolTip.key === technicalSpecKeyword.key
    )

    if (technicalTooltip) {
      const countryPath = countryToPath(store.country || DEFAULT_REGION.country)

      const learnMoreLink = technicalTooltip.learn_more
        ? {
            link: `/${countryPath}${technicalTooltip.learn_more.data?.path}`,
            linkText: t("learnMore")
          }
        : undefined

      return (
        <Flex key={`tdp-${index}-${idx}-${technicalSpecKeyword}`}>
          <Tooltip
            id={`tdp-${index}-${idx}`}
            position="top"
            title={technicalSpecKeyword.value}
            description={technicalTooltip.description[0].text}
            link={learnMoreLink?.link}
            linkText={learnMoreLink?.linkText}
          >
            <Label2 as="p">{technicalSpecKeyword.value}</Label2>
          </Tooltip>
        </Flex>
      )
    } else {
      return (
        <Label2 as="p" key={`tdp-${index}-${idx}-${technicalSpecKeyword}`}>
          {technicalSpecKeyword.value}
        </Label2>
      )
    }
  }

  return (
    <Grid
      as="dl"
      columns={["auto", "auto"]}
      gap={[4, null, 24, 32]}
      sx={{
        width: "100%",
        rowGap: [0, null, 0]
      }}
    >
      {warrantyAndRepair && (
        <Label2 sx={{ mb: [16, null, 12] }}>{warrantyAndRepair}</Label2>
      )}
      {values.map((kv, index) => {
        return (
          <React.Fragment key={`spec-${index}-${kv.name}`}>
            <Box as="dt" sx={{ color: "grey.1" }}>
              <Label3
                sx={{ color: "black.1", fontWeight: FontWeight.textBold }}
              >
                {kv.name}
              </Label3>
            </Box>
            <Box
              as="dd"
              sx={{
                color: "black.1",
                mb: [16, null, 12],
                "& p": { width: "100%", display: "block" },
                "& p + p": {
                  mt: 4
                }
              }}
            >
              {kv.values.map((it, idx) => {
                return toTooltipOrLabel(index, it, idx)
              })}
            </Box>
          </React.Fragment>
        )
      })}
    </Grid>
  )
}
