import {
  ImgixAspectRatio,
  ImgIXRatio,
  processCropRatios,
  ZoomGalleryImage
} from "@sixty-six-north/ui-system"
import { PreviewData } from "next"
import React, { useEffect, useRef, useState } from "react"
import { MediaSlice } from "../../prismic/slices/Media"
import { WindowSlice } from "../../prismic/slices/Window"
import { SingleProductProps } from "./Product"

import { Box } from "theme-ui"
import { webNextPrismicApiUrl } from "../../Config"
import {
  PrismicData,
  PrismicSliceType,
  SimplePrismicDocument
} from "../../prismic/PrismicModels"

export interface InTheFieldProps {
  product: SingleProductProps
  previewData?: PreviewData
  onImageClick?: (idx: number) => void
  onDataRefresh?: (d: PrismicData) => void
  productPrismicData: SimplePrismicDocument | null
}

export type InTheFieldImage = ZoomGalleryImage

const fetchDocuments = async (documentId: string, ref?: string) =>
  await fetch(webNextPrismicApiUrl("fetchDocuments"), {
    method: "POST",
    body: JSON.stringify({ ids: [documentId], ref }),
    headers: {
      "Content-Type": "application/json"
    }
  })

export const extractInTheFieldImagesFromPrismicData = (
  data: PrismicData | null
): ZoomGalleryImage[] => {
  const { body = [] } = data || {}
  const images: InTheFieldImage[] = []

  body.forEach(it => {
    const aspectRatio = it.primary.ratio as ImgixAspectRatio
    if (it.slice_type === PrismicSliceType.media && it.primary.the_image) {
      images.push({
        url: it.primary.the_image.url,
        title: it.primary.the_image.alt,
        aspectRatio
      })
    } else if (
      it.slice_type === PrismicSliceType.window &&
      it.items.length > 0
    ) {
      const ratioArray = processCropRatios(
        it.primary.window_variant,
        ImgIXRatio(aspectRatio || "none")
      ) as ImgixAspectRatio[]

      it.items.forEach((i, idx) => {
        if (i.image?.url) {
          images.push({
            url: i.image?.url,
            title: i.image?.alt,
            aspectRatio: Array.isArray(ratioArray)
              ? ratioArray[idx]
              : ratioArray
          })
        }
      })
    }
  })

  return images
}

const useFetch = ({
  product,
  productPrismicData,
  previewData,
  onDataRefresh
}) => {
  const { colorCode, category } = product
  const genericProductPrismicData = productPrismicData?.data || {}
  const cacheRef = useRef({})
  const { current: fetchCache } = cacheRef
  const [data, setData] = useState<PrismicData | null>({})
  const updatePrismicData = (prismicData: PrismicData) => {
    onDataRefresh(prismicData)
    setData(prismicData)
  }

  useEffect(() => {
    async function fetchVariantPrismicData(documentId) {
      let fetchData = {}
      if (fetchCache[documentId]) {
        fetchData = fetchCache[documentId]
      } else {
        const { ref = "" } = previewData || {}
        try {
          const r = await fetchDocuments(documentId, ref)
          const response = await r.json()
          fetchData = (Array.isArray(response) && response[0]?.data) || null
          fetchCache[documentId] = fetchData
        } catch (e) {}
      }
      updatePrismicData(fetchData)
    }
    const variantDocument =
      genericProductPrismicData?.product_variants_documents?.find(
        document =>
          document.category === category && document.color_code === colorCode
      )
    const variantDocumentId = variantDocument?.variant.id

    if (variantDocumentId) {
      fetchVariantPrismicData(variantDocumentId)
    } else {
      updatePrismicData(genericProductPrismicData)
    }
  }, [product.color.selected])

  return { data, onDataRefresh }
}

export const InTheField: React.FC<InTheFieldProps> = ({
  product,
  previewData,
  onImageClick = () => {},
  onDataRefresh = () => {},
  productPrismicData
}) => {
  const { data: prismicData } = useFetch({
    product,
    previewData,
    productPrismicData,
    onDataRefresh
  })
  const prismicSlices = prismicData?.body || []

  const getNumberOfPreviousImagesForSlice = sliceIndex => {
    let numberOfPreviousImages = 0
    for (let i = sliceIndex - 1; i > -1; i--) {
      if (prismicSlices[i].slice_type === PrismicSliceType.media) {
        numberOfPreviousImages++
      } else if (prismicSlices[i].slice_type === PrismicSliceType.window) {
        numberOfPreviousImages += prismicSlices[i].items.length
      }
    }
    return numberOfPreviousImages
  }

  const hasContent = prismicSlices.find(
    s =>
      s.slice_type === PrismicSliceType.media ||
      s.slice_type === PrismicSliceType.window
  )

  if (!prismicSlices || !prismicSlices.length) return null

  const handleImageClick = (
    sliceType: PrismicSliceType,
    sliceIndex: number,
    imageIndex?: number
  ) => {
    const previousImagesLength = getNumberOfPreviousImagesForSlice(sliceIndex)
    if (sliceType === PrismicSliceType.media) {
      return onImageClick(sliceIndex > 0 ? previousImagesLength : 0)
    } else if (sliceType === PrismicSliceType.window) {
      return onImageClick(previousImagesLength + (imageIndex || 0))
    }
  }

  return (
    <Box
      sx={{
        mt: hasContent ? [24, null, null, 0] : 0,
        mb: hasContent ? [24, null, null, 64] : 0
      }}
    >
      {prismicSlices.map((slice, idx) => {
        switch (slice.slice_type) {
          case PrismicSliceType.media:
            return (
              <MediaSlice
                key={idx}
                imageAlt={product.name}
                onMediaClick={() => handleImageClick(slice.slice_type, idx)}
                data={slice}
              />
            )
          case PrismicSliceType.window:
            return (
              <WindowSlice
                key={idx}
                imageAlt={product.name}
                onImageClick={i => handleImageClick(slice.slice_type, idx, i)}
                data={slice}
              />
            )
          default:
            return null
        }
      })}
    </Box>
  )
}
