//jsxhook

import {
  H3,
  H6,
  HeadingRegularVariants,
  TextVariants
} from "@sixty-six-north/ui-system"
import { MapMarker, GoogleMap } from "../../components/Maps"
import { RichText } from "prismic-reactjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Flex } from "theme-ui"
import { htmlSerializer } from "../htmlSerializer"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import { usePrismicLinks } from "../PrismicLinkedDocumentContext"
import { linkResolver } from "../PrismicLinks"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"

export const StoreDetailsSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const PrimaryAdapter = new PrismicPrimaryAdapter(data.primary)
  const prismicLinks = usePrismicLinks()

  const { t } = useTranslation("stores")

  const { latitude: lat, longitude: lng } = data.primary.coordinates || {}
  const markerCoords = lat && lng ? { lat, lng } : undefined

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Flex
        sx={{
          flex: 1,
          flexDirection: ["column", null, null, "row"]
        }}
      >
        <Box
          sx={{
            flex: [1, null, null, 0.5, 0.8],
            pr: [null, null, null, 56, 48],
            mb: 48
          }}
        >
          <H3 sx={{ color: "grey.0" }}>{t("openingHours")}</H3>
          <Box
            as="dl"
            sx={{
              maxWidth: [null, null, null, "48ch"],
              mt: 32,
              variant: `text.${HeadingRegularVariants.sixth}`,
              "& > dt": {
                mt: 16
              },
              "& > dd": {
                mt: 16
              }
            }}
          >
            {data.items.map((item, idx) => {
              const adapter = new PrismicItemAdapter(item)
              return (
                <React.Fragment key={`opening-time-${idx}`}>
                  <Box as="dt" sx={{ float: "left", width: "50%" }}>
                    {adapter.day("")}
                  </Box>
                  <Box
                    as="dd"
                    sx={{ float: "left", textAlign: "right", width: "50%" }}
                  >
                    {adapter.time("")}
                  </Box>
                </React.Fragment>
              )
            })}
          </Box>
        </Box>

        <Box sx={{ flex: 1.2 }}>
          <Box
            sx={{
              width: "100%",
              position: "relative",
              pb: "56%",
              height: 0
            }}
          >
            <GoogleMap center={markerCoords} zoom={14}>
              {lat && lng && (
                <MapMarker
                  label={PrimaryAdapter.label("")}
                  lat={lat}
                  lng={lng}
                ></MapMarker>
              )}
            </GoogleMap>
          </Box>

          <Flex
            sx={{
              mt: 32,
              flexDirection: ["column", null, null, "row"],
              "& > * + *": {
                mt: [24, null, null, 0]
              },
              "& p": {
                variant: `text.${TextVariants.technicalParagraphSmall}`
              }
            }}
          >
            <Box sx={{ flex: 1 }}>
              <H6 sx={{ color: "grey.0", mb: 8 }}>{t("contact")}</H6>
              <RichText
                render={data.primary.contact}
                linkResolver={linkResolver(prismicLinks)}
                htmlSerializer={htmlSerializer(prismicLinks, false)}
              />
            </Box>

            <Box sx={{ flex: 1 }}>
              <H6 sx={{ color: "grey.0", mb: 8 }}>{t("address")}</H6>
              <RichText
                render={data.primary.address}
                linkResolver={linkResolver(prismicLinks)}
                htmlSerializer={htmlSerializer(prismicLinks, false)}
              />
            </Box>

            <Box sx={{ flex: 1 }}>
              <H6 sx={{ color: "grey.0", mb: 8 }}>{t("directions")}</H6>
              <RichText
                render={data.primary.directions}
                linkResolver={linkResolver(prismicLinks)}
                htmlSerializer={htmlSerializer(prismicLinks, false)}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </PrismicElementWrapper>
  )
}
