import _intersection from "lodash/intersection"
import { filterTruthy } from "../utils/Filter"
import { DomainCategory } from "./models/DomainCategory"
import { SimpleAsset } from "./models/SimpleAsset"

export type TopLevelCategoryKey = "men" | "women" | "kids"

export function assetsTaggedFor(
  parentCategories: DomainCategory[]
): (asset: SimpleAsset) => boolean {
  if (parentCategories.length === 0) {
    return () => true
  }

  const topCategoryKeys: string[] = parentCategories.map(
    topCategory => topCategory.key
  )

  return (asset: SimpleAsset) => {
    const assetTags = asset.tags.map(tag => tag.toLocaleLowerCase())
    const assetTaggedForTopCategories =
      _intersection(assetTags, topCategoryKeys).length > 0

    return (
      assetTags.length === 0 ||
      (assetTags.length === 1 && ["product", "model"].includes(assetTags[0])) ||
      assetTaggedForTopCategories
    )
  }
}

export function assetsForCategory(
  category: DomainCategory
): (asset: SimpleAsset) => boolean {
  const categories =
    (category?.ancestors || []).length > 0
      ? category.ancestors
      : category
        ? [category]
        : []

  return assetsTaggedFor(categories)
}
